/** @format */

import { TableKey } from '@common/application/enums/TableKey';
import useColumnSorting from '@common/application/hooks/useColumnSorting/useColumnSorting';
import useDefaultSettings, { TableConfig } from '@common/application/hooks/useDefaultSettings/useDefaultSettings';
import useUserSettings, { SettingsKey } from '@common/application/hooks/useUserSettings/useUserSettings';

const useTableSettings = (tableKey: TableKey) => {
  const defaultSettings = useDefaultSettings();
  const { settings: tableSettings, setSettings, saveChanges, undoChanges, isLoaded } = useUserSettings(SettingsKey.TABLES, defaultSettings.tableSettings);
  const settings = tableSettings[tableKey] as TableConfig;
  // Due to a lack of foresight in the way I made this, need to check if the columns used have changed since the user last saved their settings and add them in
  // E.g. if a new column is added to a table, it won't be in the users saved columns
  const savedCols = settings?.columns;
  const defaultCols = defaultSettings.tableSettings[tableKey]?.columns;
  if (savedCols?.length !== defaultCols?.length) {
    // Columns added since user saved settings
    const newCols = defaultCols.filter((col) => !savedCols.includes(col));
    // Columns in user's saved settings that are no longer supported
    const deletedCols = savedCols.filter((col) => !defaultCols.includes(col));
    // By splicing in the new columns, the user's preferred column order preserved apart from the new columns
    newCols.forEach((col) => savedCols.splice(defaultCols.indexOf(col), 0, col));
    deletedCols.forEach((col) => savedCols.splice(savedCols.indexOf(col), 1));
    settings.mandatoryColumns = defaultSettings.tableSettings[tableKey].mandatoryColumns;
  }
  const defaultSort = useColumnSorting(settings?.defaultSort);
  return { settings, defaultSort, setSettings, saveChanges, undoChanges, isLoaded };
};

export default useTableSettings;
