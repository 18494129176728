/** @format */

import { TableKey } from '@common/application/enums/TableKey';
import useTableSorting from '@common/application/hooks/useTableSorting/useTableSorting';
import { useCallback, useEffect, useState } from 'react';

const useSearchableTable = (tableKey: TableKey, config?: { throttle: boolean }) => {
  // Elasticsearch Point In Time
  const [pit, _setPit] = useState();
  const [searchAfter, setSearchAfter] = useState();
  const [pageHistory, setPageHistory] = useState([]);
  const [search, _setSearch] = useState('');
  const [sort, setSort] = useTableSorting(tableKey, search);

  //   Reset pagination on search
  const setSearch = useCallback((value: string) => {
    _setSearch(value);
    setSearchAfter(null);
    setPageHistory([]);
  }, []);

  // set Pit
  const setPit = useCallback(function (data) {
    if (data?.pit_id) {
      _setPit(JSON.stringify({ id: data.pit_id }) as undefined);
    } else {
      _setPit(null);
    }
  }, []);

  const nextPage = useCallback(
    (data) => {
      const s = data.payload.slice(-1)[0].sort;
      setPageHistory((pageHistory) => [...pageHistory, searchAfter]);
      setSearchAfter(s);
    },
    [searchAfter],
  );

  const previousPage = useCallback(() => {
    setSearchAfter(() => pageHistory.slice(-1)[0] as undefined);
    setPageHistory((pageHistory) => pageHistory.slice(0, -1));
  }, [pageHistory]);

  return { search, setSearch, pit, setPit, nextPage, previousPage, sort, setSort, searchAfter, setSearchAfter, pageHistory, setPageHistory };
};

export default useSearchableTable;
