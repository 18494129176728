/** @format */

import Modal from '@atoms/Modal';
import { cloneElement, ReactElement, useEffect } from 'react';
import { useOverlayTrigger } from 'react-aria';
import { OverlayTriggerProps, useOverlayTriggerState } from 'react-stately';

function ModalTrigger({ label, children, ...props }: OverlayTriggerProps & { label: string; children(close): ReactElement; button: ReactElement }) {
  const state = useOverlayTriggerState(props);
  const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state);

  const close = () => {
    state.close();
  };

  return (
    <>
      {cloneElement(props.button, { ...triggerProps, onClick: state.open })}
      {state.isOpen && (
        <Modal {...props} state={state} isDismissable>
          {cloneElement(children(close), overlayProps)}
        </Modal>
      )}
    </>
  );
}

export default ModalTrigger;
